import { Navigate, useLocation } from "react-router-dom";
// import store from "../store/app.store";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
    let location = useLocation();

    const isSignedIn = true; //store.getState().authentication.signedIn;

    if (!isSignedIn) {
        return <Navigate to={`/signin?redirect=${location.pathname}${location.search}`} state={{ from: location }} />;
    }

    return children;
};

export default ProtectedRoute;
