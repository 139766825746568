import { AppShell, Burger, Flex } from '@mantine/core';
import { useDisclosure } from '@mantine/hooks';
import { Outlet } from 'react-router-dom';
import logo from "../../assets/logo.jpg";
import MyNavLink from '../components/MyNavLink';
const MainWrapper = () => {
    const [opened, { toggle }] = useDisclosure();
    return (
        <AppShell
            header={{ height: 60 }}
            navbar={{
            width: 300,
            breakpoint: 'sm',
            collapsed: { mobile: !opened },
            }}
            padding="md"
        >
            <AppShell.Header>
                <Flex align="center">
                    <Burger
                        opened={opened}
                        onClick={toggle}
                        hiddenFrom="sm"
                        size="sm"
                    />
                    <img src={logo} width={100} height={50} alt="Cités des cultures" />
                </Flex>
            </AppShell.Header>

            <AppShell.Navbar p="md">
                <MyNavLink />
            </AppShell.Navbar>

            <AppShell.Main>
                <Outlet />
            </AppShell.Main>
        </AppShell>
    )
}

export default MainWrapper;