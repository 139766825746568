import { Title } from "@mantine/core"

interface DashboardTitleProps {
    text: string;
    order?: number;
    color?: string;
}
const DashboardTitle: React.FC<DashboardTitleProps> = ({text, order, color}) => {
    return <Title order={3} style={{color: color ?? "black"}}>{text}</Title>;
}

export default DashboardTitle;