import { Text, Paper, Title } from '@mantine/core';
interface DashboardPaperProps {
    totalNumber: number;
    description: string;
}
const DashboardPaper: React.FC<DashboardPaperProps> = ({totalNumber, description}) => {
    return (
        <Paper shadow="xs" p="xl" style={{textAlign: "center"}}>
            <Text>
                {description}
            </Text>
            <Title order={1} style={{color: "#F2A402"}}>{totalNumber}</Title>            
        </Paper>
    );
}

export default DashboardPaper;