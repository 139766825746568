import './App.css';
import "@mantine/core/styles.css";
import { MantineProvider, createTheme, rem } from '@mantine/core';
import { RouterProvider } from 'react-router-dom';
import MyRoutes from './Routes/myRoutes';

const theme = createTheme({
  focusRing: "always",
  primaryColor: "cdcPrimary",
  defaultRadius: "md",
  defaultGradient: {
    from: "a2zOrange",
    to: "a2zPink",
    deg: 45,
  },
  colors: {
    // Add your color
    cdcPrimary: [
      "#9C2781",
      "#9C2781",
      "#9C2781",
      "#9C2781",
      "#9C2781",
      "#9C2781",
      "#9C2781",
      "#9C2781",
      "#9C2781",
      "#9C2781"
    ],
    a2zDeepBlue: [
      "#eef3ff",
      "#dce3f5",
      "#b8c5e2",
      "#93a5d1",
      "#7389c2",
      "#5e78b9",
      "#536fb5",
      "#435ea0",
      "#395490",
      "#2c4881"
    ],
    a2zLightBlue: [
      "#e4faff",
      "#d5f1f7",
      "#afdfeb",
      "#86cdde",
      "#64bed3",
      "#4db5cd",
      "#3eb0ca",
      "#2c9ab3",
      "#198aa1",
      "#00788e"
    ],
    a2zOrange: [
      "#fff4e1",
      "#ffe6cd",
      "#fbcd9e",
      "#f8b16c",
      "#f49940",
      "#f38a24",
      "#f38213",
      "#d86f05",
      "#c16200",
      "#a85200"
    ],
    a2zPink: [
      "#ffe8f9",
      "#ffcfeb",
      "#ff9cd2",
      "#fe65b9",
      "#fd3aa4",
      "#fd1f97",
      "#fe1090",
      "#e3007c",
      "#cb006e",
      "#b20060"
    ],
    a2zPrimary: [
      "#e5f5ff",
      "#d0e7ff",
      "#a2cbf8",
      "#72aef1",
      "#4996eb",
      "#2f86e8",
      "#1c7fe8",
      "#076ccf",
      "#0060bb",
      "#0053a5"
    ]
  },

  shadows: {
    md: "1px 1px 3px rgba(0, 0, 0, .25)",
    xl: "5px 5px 3px rgba(0, 0, 0, .25)",
  },

  headings: {
    fontFamily: "Roboto, sans-serif",
    sizes: {
      h1: {fontSize: rem(36)},
    },
  },
});

function App() {
  return (
    <MantineProvider theme={theme}>
      <RouterProvider router={MyRoutes} />      
    </MantineProvider>
  );
}

export default App;
