import { NavLink } from '@mantine/core';
import { 
    IconHome2, 
    IconCalendarEvent, 
    IconBuilding, 
    IconUsersGroup, 
    IconCertificate, 
    IconMoneybag,
    IconAffiliate,
    IconAdjustments,
    IconUsers
} from '@tabler/icons-react';
import { ReactNode } from 'react';
import { Link, useLocation } from 'react-router-dom';

interface INavLink {
    label: string;
    href: string;
    active?: boolean;
    icon?: ReactNode;
    leftSection?: ReactNode;
    rightSection?: ReactNode;
    items?: INavLink[];
}

const MENU: INavLink[] = [{
    label: "Accueil",
    href: "dashboard",
    leftSection: <IconHome2 size="1rem" stroke={1.5} />
}, {
    label: "Evénements",
    href: "events",
    leftSection: <IconCalendarEvent size="1rem" stroke={1.5} />
}, {
    label: "Location salle",
    href: "rental",
    leftSection: <IconBuilding size="1rem" stroke={1.5} />
}, {
    label: "Membres",
    href: "members",
    leftSection: <IconUsersGroup size="1rem" stroke={1.5} />
}, {
    label: "Cours",
    href: "courses",
    leftSection: <IconCertificate size="1rem" stroke={1.5} />
}, {
    label: "Finance",
    href: "finance",
    leftSection: <IconMoneybag size="1rem" stroke={1.5} />
}, {
    label: "Collaborateurs/Partenaires",
    href: "collaborateurs",
    leftSection: <IconAffiliate size="1rem" stroke={1.5} />
},
{
    label: "Paramétrage",
    href: "parametrage",
    leftSection: <IconAdjustments size="1rem" stroke={1.5} />
},
{
    label: "Utilisateurs",
    href: "utilisateurs",
    leftSection: <IconUsers size="1rem" stroke={1.5} />
}];

function MyNavLink() {
    const location = useLocation();    
    return (
        <>
            {MENU.map((menu: INavLink, i: number) => {
                return <Link to={menu.href} key={`menu-${i}`} style={{textDecoration: "none", color: "inherit", border: "none"}}>
                    <NavLink
                        {...menu}
                        variant="filled"                    
                        active={`/${menu.href}` === location.pathname}                        
                    />
                </Link>
            })}      
        </>
    );
}

export default MyNavLink;