import { Navigate, createBrowserRouter } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import MainWrapper from "../shared/Layout/MainWrapper";
import DashboardContainer from "../pages/Dashboard/container/dashboard.container";
import UsersContainer from "../pages/Users/containers/users.container";

const MyRoutes = createBrowserRouter([
    {
        path: "/login",
        element: <>Login</>
    },    
    {
        path: "/",
        element: <ProtectedRoute><MainWrapper /></ProtectedRoute>,
        children: [
            {
                path: "dashboard",
                element: <DashboardContainer />
            },
            {
                path: "events",
                element: <>EVENTS</>
            },
            {
                path: "rental",
                element: <>LOCATION SALLE</>
            },
            {
                path: "members",
                element: <>MEMBRES</>
            },
            {
                path: "courses",
                element: <>COURS</>
            },
            {
                path: "finance",
                element: <>FINANCE</>
            },
            {
                path: "collaborateurs",
                element: <>Collaborateurs/Partenaires</>
            },
            {
                path: "parametrage",
                element: <>Parametrage</>
            },
            {
                path: "utilisateurs",
                element: <UsersContainer />
            },
            {
                path: "*",
                element: <Navigate replace to="/dashboard" />
            },
        ]
    }
]);

export default MyRoutes;